import React, { Component } from 'react';
import api from '../../api/index'
import browserHistory from '../../config/browserHistory'
import LoadingOverlay from 'react-loading-overlay';
import moment from 'moment';
import { Modal, Button, Col, Row } from 'antd';
import 'antd/dist/antd.css';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {connect} from 'react-redux';
import { fetchQuotations, reviseOk, updateStatus } from './services/quotations';
import { getQuotationSuccess, setRejectVisible, setVisible } from './quotationsSlice';
import { Select } from 'antd';
import Form from 'antd/lib/form/Form';
import { Input } from 'antd';
import Yantra from '../../images/yantra.png';
import LeftQuote from '../../images/left-quotes-sign.png';
import Download from '../../images/download (2).png';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Reject from '../../images/error.png';
import Confirm from '../../images/confirm (1).png';
import Revise from '../../images/wall-clock1.png';
import DownloadIcon from '../../images/direct-download (1).png';
import LightTooltip from '../../config/tooltip';
import NoData from '../../images/no-data-concept-illustration_114360-536.jpg';



const { TextArea } = Input;

const { Option } = Select;

class Quotation extends Component {
	currentUser={};

	state={
		nextPage: false,
        nextPageSize: 0,
		quotations:[],
		isLoading:false,
		visible: false,
		rejectVisible:false,
		revise:'',
		time:new Date,
		quotation_id:'',
		comments:'',
		quote:{},
		reasonId:'',
			options: [
				{
				"id": "12000102911", 
				"name": "Expected Lower Price", 
				},
				{
				"id": "12000258287", 
				"name": "Expected Lesser Delivery time ", 
				},
				{
				"id": "12000258288", 
				"name": "Expected Item in Different Brand", 
				},
				{
				"id": "12000258289", 
				"name": "Given quote is not complete", 
				},
				{
				"id": "12000258290", 
				"name": "Need to Change items in this quotation same", 
				},
				{
				"id": "12000102916", 
				"name": "I got quote for wrong item", 
				},
				{
				"id": "12000258290", 
				"name": "I need to add more items to this quote same", 
				},
				
			]
	}



	componentWillMount() {
		//this.setState({isLoading:true})
		this.currentUser=JSON.parse(localStorage.getItem("currentUser"))
		//this.getQuotations(1);
		this.props.dispatch(setVisible(false))
		this.props.dispatch(setRejectVisible(false))
		this.props.dispatch(fetchQuotations(1));
		
	}



	downloadQuatation=(url)=>{
		window.window.open(url,'_blank');
	}

	

	updateQuotation=(quotation, state)=>{
		
		// let orderId=uuidv4();
		console.log(this.state.reasonId)
		let reason_id = this.state.reasonId.split(" ")
		let strA3 = this.state.reasonId.split(' ')[0];                    // "72";
		let strB3 = this.state.reasonId.split(' ').splice(1).join(' '); 
		console.log(strA3)
		console.log(strB3)
		
		this.setState({isLoading:true})
		let data = {
			reasonForRejection: strB3+ " - " + this.state.comments,
			dealReasonId: strA3,
			enquiryId: quotation.enquiryId,
			pdfUrl:quotation.pdfUrl,
			status:state,
			amount: quotation.amount,
			quotationId:quotation.quotationId,
			customerId: this.currentUser.uid
			// order_id: orderId
		}

		console.log(data)

		this.props.dispatch(updateStatus(data, state))
		
	}

	showModal = (quotation_id) => {
		this.props.dispatch(setVisible(true))
    	this.setState({quotation_id:quotation_id,revise:''});
  	};

	reviseCancel = () => {
		document.getElementById("revise").value="";
		this.props.dispatch(setVisible(false))
    	//this.setState({visible: false});
	}

	reviseOk = async() => {
		this.setState({isLoading:true})
		let data= {
			customerClarification:this.state.revise,
			quotationId:this.state.quotation_id
		}
		
		await this.props.dispatch(reviseOk(data))
		if(this.props.reviseSuccess){
			this.setState({revise:'',quotation_id:'',})
		}
		
	}

	onSort=(event, sortKey)=>{
		debugger
		let data = [...this.props.quotations];
		data.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
		// this.setState({quotations:data})
		this.props.dispatch(getQuotationSuccess(data))
	}

	pagination=()=>{
		var page = []
		for (let i = 1; i <= Math.ceil(this.props.result/5); i++) {
			page.push(<li className="paginate_button page-item">
				{this.props.pageNo==i?
					<li className="paginate_button page-item active">
						<button aria-controls="dataTable" className="page-link rounded-circle" onClick={() =>this.props.dispatch(fetchQuotations(i))} >{i}</button> </li>:
				<button aria-controls="dataTable" className="page-link rounded-circle" onClick={() =>this.props.dispatch(fetchQuotations(i))}>{i}</button>
				}
		</li>)
		}
		return page;
	}

	
	render() {

		const {quotations, loading, pageNo, result} = this.props
		const {options} = this.state
		console.log(options)
		
		return (
			<React.Fragment>
			<div className='enquiry_body'>
				<ToastContainer />
				<LoadingOverlay active={loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				<div className="row quotation_panel d-flex flex-column">  
					<h4 class="page--title h5 text-white ml-4 pl-4">My Quotations</h4>
					<ul class="d-flex flex-row">
						<li className="text-white"><Link style={{color:"#CACACA"}} className=" ml-4 pl-4" to="/dashboard">Dashboard &nbsp;/ &nbsp;</Link></li>
						<li className="text-white"><span > My Quotations</span></li>
					</ul>
				</div>  

				<div className="pl-4 pr-4 ml-4 mr-4 list_table">
					<div className='card card_shadow'>
						<div class="card-header pl-4 pr-4 ">
							<div className='table_top'>
								<h4 className='table_top_head d-flex flex-row '><div className="icon-top"><img src={LeftQuote} width="20px" height="20px" ></img></div>&emsp;Quotations List</h4>
								
								<p className='pl-4 note_text'>&emsp; &emsp;   Found Total {result ? result : '0' } Quatations</p>
							</div>
							{quotations.length > 0 ? 
							<div class="table-responsive">
								<table class="table " id="dtBasicExample">
									<thead className="enquiry_list_th" >
										<tr>
											<th scope="col" onClick={e => this.onSort(e, 'enquiryId')}>Enquiry Id</th>
											<th scope="col" onClick={e => this.onSort(e, 'title')} >Title</th>
											<th scope="col" onClick={e => this.onSort(e, 'createdAt')}>Created At</th>
											<th scope="col" onClick={e => this.onSort(e, 'updatedAt')}>Updated At</th>
											<th scope="col" >Expire On</th>
											<th scope="col" >Status</th>
											<th scope="col">Quatation age</th>
											<th scope="col">Quatation value</th>
											<th scope="col" className='text-center'>Download</th>
											<th scope="col" className='text-center'>Order</th>
										</tr>
									</thead>
									<tbody className="enquiry_list_tbody">
										{quotations.map(quotation => {
											return(
											<tr role="row" className="odd">
												<td className=' align-middle'>{quotation.enquiryId}</td>
												<td className=' align-middle text-truncate' style={{maxWidth: "100px", }}>{quotation.title}</td>
												<td className=' align-middle' style={{maxWidth: "150px", }}>{(quotation.createdAt)}</td>
												<td className=' align-middle' style={{maxWidth: "150px", }}>{(quotation.updatedAt)}</td>
												<td className=' align-middle' style={{maxWidth: "150px", }}>{(quotation.expireOn)}</td>
												<td className=' align-middle'>{quotation.status}</td>
												<td className=' align-middle' style={{maxWidth: "30px", }}>{parseInt(quotation.quotationAge)}</td>
												<td className=' align-middle'>Rs {quotation.amount}/-</td>
												<td className=' align-middle text-center pr-0'>
														<img src={DownloadIcon} height="22px" style={{cursor:"pointer"}} className="" onClick={()=>this.downloadQuatation(quotation.pdfUrl)} />
														
												</td>		
												<td className='align-middle text-center' style={{minWidth: "120px", }}>
													{/* {quotation.status !='published' ?
														<button className="btn btn-outline-info btn-sm mr-2" disabled={quotation.status !='published'} onClick={()=>this.updateQuotation(quotation)}>
															<i class="fas fa-shopping-cart"></i> Confirmed
														</button> :
														<button className="btn btn-outline-info btn-sm mr-2" onClick={()=>this.updateQuotation(quotation,"confirmed")}>
															<i class="fas fa-shopping-cart"></i> Confirm
														</button>
													} */}
													<LightTooltip title="Confirm Order" placement="top-start">
														<button style={{color: "#00F729", border:"none",backgroundColor:"transparent", outline:"none", cursor:"pointer"}} className="mr-2" disabled={quotation.status !='published'} 
															onClick={()=>this.updateQuotation(quotation, "confirmed")}>
															<img src={Confirm} height="22px" />
														</button>
													</LightTooltip>
													<LightTooltip title="Reject Quotation" placement="top-start">
														<button style={{border:"none", backgroundColor:"transparent", outline:"none", outline:"none", cursor:"pointer"}} className="mr-2" disabled={quotation.status !='published'} 
															onClick={()=>{ 
																this.props.dispatch(setRejectVisible(true))
																this.setState({quote:quotation,reason:'', reasonId:''}) 
															}}>
															<img src={Reject} height="22px" />
														</button>
													</LightTooltip>
													<LightTooltip title="Revise Quotation" placement="top-start">
														<button style={{border:"none", backgroundColor:"transparent", outline:"none", outline:"none", cursor:"pointer"}} className="" disabled={quotation.status !='published'} 
															onClick={()=> {
																this.props.dispatch(setVisible(true))
																this.setState({quotation_id:quotation.quotationId,revise:''})
																}
															}>
															<img src={Revise} height="22px" />
														</button>
													</LightTooltip>
												</td>						
											</tr>
											)
											})}		
									</tbody>
								</table>
								<ul className="pagination float-right align-items-center mt-4 mr-4" >
									{pageNo<=1?
										<li className="paginate_button page-item previous disabled" id="dataTable_previous">
											<button className="page-link1 disabled not-allowed">Previous</button>
										</li>:
										<li className="paginate_button page-item previous" id="dataTable_previous">
											{/* <button className="page-link" onClick={() =>this.getResturants(this.state.pageNo-1)}>Previous</button> */}
											<button className="page-link1" onClick={() =>this.props.dispatch(fetchQuotations(pageNo-1))} >Previous</button>
										</li>
									}
									<div className="page-no">{pageNo}</div>
									{pageNo>= Math.floor(result/5)?
										<li className="paginate_button page-item next disabled" id="dataTable_next">
											<button className="page-link disabled not-allowed" >Next</button>
										</li>:
										<li className="paginate_button page-item next " id="dataTable_next">
											<button className="page-link" onClick={() =>this.props.dispatch(fetchQuotations(pageNo+1))}>Next</button>
										</li>
									}
								</ul> 
							</div>
							:
							<React.Fragment>
								<hr className="create__enq__hr"></hr>
								<div class="table-responsive d-flex flex-column justify-content-center align-items-center">
										<span className="no_data_text mt-4 mb-2">
											This screen will be empty if you have not created any enquiry,
										</span>
										<span className="no_data_text mb-2">
											if you have submitted your enquiry please wait while our team is preparing best quotation  for your requirement 
										</span>
										<img src={NoData} height="300px"></img>
								</div>
							</React.Fragment>
							}

							<Modal
								visible={this.props.visible}
								title={<p style={{color:"#0D4A61", fontWeight:"bold", fontSize:"18px"}}>Revise Quotation</p>}
								onCancel={() => this.props.dispatch(setVisible(false))}
								footer={[
									<button style={{color:"white", backgroundColor:"#FDC32C", border:"none", outline:"none", cursor:"Pointer"}} className='mr-1 mb-2 pl-4 pr-4 pt-2 pb-2' onClick={this.reviseCancel}>Cancel</button>,
									<button style={{color:"white", backgroundColor:"#FDC32C", border:"none", outline:"none", cursor:"Pointer"}} className='mr-1 pl-4 pr-4 pt-2 pb-2' onClick={this.reviseOk}>Send</button>,
								]}
							>
								<label for="revise">Feedback Message</label>
								<textarea class="form-control" id="revise" defaultValue="" rows="3"
								onChange={(e)=>this.setState({revise:e.target.value})}></textarea>
								
							</Modal>

							<Modal
								visible={this.props.rejectVisible}
								title={<p style={{color:"#0D4A61", fontWeight:"bold", fontSize:"18px"}}>Reject Quotation</p>}
								onCancel={() => this.props.dispatch(setRejectVisible(false))}
								footer={<button style={{color:"white", backgroundColor:"#FDC32C", border:"none", outline:"none", cursor:"Pointer"}} className="mr-1 mb-2 pl-4 pr-4 pt-2 pb-2" onClick={()=>this.updateQuotation(this.state.quote, "rejected")}>Reject</button>}
							>
							<Form>
								<Select className="select__reason" defaultValue="Please select a reason for rejecting this quotation " name="reason_options" style={{ width: 470 }} onChange =  {(value) => {
	
									this.setState({reasonId : value})
								}}>
									{options.map((el, index) => (
										<Option value={el.id + " " +el.name}>{el.name} </Option>
									))}
								</Select>
								
								<label className="mt-4" for="comments">Comments:</label>
								<TextArea rows={4}  id="comments" defaultValue="" onChange={(e)=>this.setState({comments:e.target.value})}/>
	
							</Form>
								
								{/* <textarea class="form-control"  rows="2"
									></textarea> */}
	
							</Modal>

					

						</div>
					</div>
				</div>
				</LoadingOverlay>
				
			</div>
			<div className="dashboard_layout_footer mb-4 text-blue" >
				<a href="https://www.yantralive.com/" target="_blank" className="text-blue ml-4  pl-4"> www.yantralive.com</a>
				<img src={Yantra}  height="60px"></img>
				<a className="text-blue pr-4 mr-4">© 2021 - All Right Reserved</a>
			</div>
			</React.Fragment>
		);
	}
}


const mapStateToProps = (state, ownProps) => {
	console.log(state)
    return {
		quotations:state.quotationsSlice.quotations,
		pageNo: state.quotationsSlice.pageNo ,
		result: state.quotationsSlice.totalElements,
		loading: state.quotationsSlice.loading,
		visible:  state.quotationsSlice.visible,
		rejectVisible:  state.quotationsSlice.rejectVisible,
		reviseSuccess: state.quotationsSlice.reviseSuccess,
		
    }
};


export default connect(mapStateToProps)(Quotation);


