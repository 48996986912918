import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';


const LightTooltip = withStyles((theme) => ({
	tooltip: {
	  backgroundColor: "#0d4a61",
	  color: '#FFFFFF',
      fontSize: 11,
      paddingTop: 10,
      paddingBottom: 10,  
	},
  }))(Tooltip);
  

  export default LightTooltip