import React, { Component } from 'react';
import './login.css';
import fire from '../../config/fire';
import firebase from 'firebase';
import browserHistory from '../../config/browserHistory'
import { Col, Modal, Row } from 'antd';
import OtpLogin from './OtpLogin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api/index'
import LoadingOverlay from 'react-loading-overlay';
import { Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import {connect} from 'react-redux'
import { googleLogin, login, register } from './services/user';
import { setHide, setInitial } from './userSlice';
import Logo from '../../images/Logo.png';
import GoogleIcon from '../../images/google-login-icon-png.png';
import PhoneIcon from '../../images/phone-call.png';
import Banner1 from '../../images/yantralive-new-banner3.png';
import Banner2 from '../../images/yantralive-new-banner2.png';


const layout = {
	
	wrapperCol: { span: 24 },
  };
  
  const layout1 = {
	  labelCol: { span: 6 },
	  wrapperCol: { span: 16 },
	};
  

class Login extends Component {
	
	state={
		loginCss:'login',
		regCss:'register',
		btnCss:'btn',
		boxcss:'form-box',
		loginEmail:'',
		loginPassword:'',
		regEmail:'',
		regPassword:'',
		confirmPassword:'',
		name:'',
		visible:false,
		isLoading:false,
		pwd_visible: false,
		newpwd: ''
	}

	componentWillMount() {
		this.props.dispatch(setInitial())
		this.props.dispatch(setHide(false))
	}

	handleChange = (e) => {
		this.setState({[e.target.name]:e.target.value})
	}

	login = (e) => {
		e.preventDefault();
		const {loginEmail, loginPassword} = this.state
		const payload = {
			email:loginEmail,
			password: loginPassword
		}

		this.props.dispatch(login(payload))
		
		
	}

	googleLogin = (e) => {
		e.preventDefault();
		this.props.dispatch(googleLogin())
		
		
	}


	register = (e) => {
		e.preventDefault();
		this.setState({isLoading:true})
		if(this.state.regPassword===this.state.confirmPassword){
			let payload = {
				name: this.state.name,
				email: this.state.regEmail,
				password: this.state.regPassword
			}
			this.props.dispatch(register(payload))
			
			
		}
		else{
			toast.info("Password Mismatch", {position: "top-center"})
			this.setState({isLoading:false})
		}
	}

	

	resetPassword=()=>{
		fire.auth().sendPasswordResetEmail(this.state.newpwd).then(res => {
			console.log(res)
			browserHistory.push('/login')
		}).catch(err=>{
			toast.info(err.message, {position: "top-center"})
		})
	}

	clearModal = () => {
		this.setState({pwd_visible:false})
		browserHistory.push('/login')
	}

	render() {
		return (
			<div className={this.state.loginCss=='login' ? 'hero' : 'hero'}>
				<LoadingOverlay active={this.props.loading} spinner styles={{overlay: (base) => ({ ...base, position: 'fixed', })}}>
				<ToastContainer />
				<div className={this.state.loginCss=='login' ? 'd-flex' : 'd-flex flex-row-reverse'}>
					
						<img className="img_banner1" src={this.state.loginCss=='login' ? Banner2 : Banner1} />
					
				<div className="form-box" hidden={this.state.boxcss === "form-box1"}>
					<div className='logo d-flex justify-content-center'>
						<img alt="logo" width='80px' height='90px' src={Logo}/>
					</div>
					<div className='d-flex flex-column justify-content-center align-items-center'>
						<div className="welcome-text" >Welcome to Yantralive </div>
						<div className="jss-text" >Your One Stop Shop for Purchasing Parts</div>
						<div className="jss-text" >Login / Signup to access your account</div>
					</div>
					<div className='button-box d-flex justify-content-between'>
						<div id={this.state.btnCss} className='bttn'></div>{() => this.setState({visible:false})}
						<div className="toggle_btn" onClick={()=>this.setState({loginCss:'login', regCss:'register',btnCss:'btn', boxcss:"form-box"})}>Sign In</div>
						<div className="toggle_btn" onClick={()=>this.setState({loginCss:'login1', regCss:'register1',btnCss:'btn1', boxcss:"form-box1"})}>Register</div>
					</div>

					<div className='social-icons'>
						
						<span className='' onClick={this.googleLogin}> 
							<img src={GoogleIcon} height="35px" />
						</span>
						<span className='' onClick={()=>this.setState({visible:true})}> 
							<img src={PhoneIcon} height="37px" />
						</span>
						
					</div>
					
					<Form id={this.state.loginCss} className='input-group'>
						<Row>
							<Col span={24}>
								<Form.Item name="email" {...layout}
									rules={[
										{ type: "email", message: "The input is not valid E-mail!" },
										{ required: true, message: "Please input your E-mail!" }
									]}
								>
									<Input autocomplete="off" name='loginEmail' placeholder='* Email'
										onChange={this.handleChange} className='input-field'
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item name="password"  {...layout}
									rules={[{ required: true, message: "Please input Password!" }]}
								>
									<Input autocomplete="off" className='input-field' type='password'
										name='loginPassword' placeholder='* Password' onChange={this.handleChange}
									/>
								</Form.Item>
							</Col>
						</Row>				
					</Form>
					<button type='submit' className='submit-btn mt-3' onClick={this.login}>Log in</button>

					
					<div id={this.state.loginCss} className='content-group d-flex flex-column justify-content-center align-items-center flex-wrap'>
					<div className="forgot__Pwd" onClick={()=>this.setState({pwd_visible:true})}>Forgot Password? </div>
						<div className="toggle_btn1 mt-1" onClick={()=>this.setState({loginCss:'login1', regCss:'register1',btnCss:'btn1', boxcss:"form-box1"})}>Don’t have an account? Click Here to Sign Up </div>
						<div className="mt-2 pt-2  pl-4 " style={{marginLeft: "8rem", font: 'normal normal bolder 13px Sitka Small',letterSpacing:' 0px',color:'#1A5865',opacity: 1}}>When you sign up, you can track your enquiries, view quotes and status of  </div>
						<div className=" mb-2 pl-4 " style={{marginLeft: "9rem", font: 'normal normal bolder 13px Sitka Small',letterSpacing:' 0px',color:'#1A5865',opacity: 1}}>your orders. Exclusive offers are available for registered users only </div>
						
					</div>
				</div>
				
				<div className="form-box" hidden={this.state.boxcss === "form-box"}>
					<div className='logo d-flex justify-content-center'>
						<img alt="logo" width='80px' height='90px' src={Logo}/>
					</div>
					<div className='d-flex flex-column justify-content-center align-items-center'>
						<div className="welcome-text" >Welcome to Yantralive </div>
						<div className="jss-text" >Your One Stop Shop for Purchasing Parts</div>
						<div className="jss-text" >Login / Signup to access your account</div>
					</div>
					<div className='button-box d-flex justify-content-between'>
						<div id={this.state.btnCss} className='bttn'></div>{() => this.setState({visible:false})}
						<div className="toggle_btn" onClick={()=>this.setState({loginCss:'login', regCss:'register',btnCss:'btn', boxcss:"form-box"})}>Sign In</div>
						<div className="toggle_btn" onClick={()=>this.setState({loginCss:'login1', regCss:'register1',btnCss:'btn1', boxcss:"form-box1"})}>Register</div>
					</div>

					<div className='social-icons'>
						
						<span className='' onClick={this.googleLogin}> 
							<img src={GoogleIcon} height="30px" />
						</span>
						<span className='' onClick={()=>this.setState({visible:true})}> 
							<img src={PhoneIcon} height="33px" />
						</span>
						
					</div>
					<Form id="login" className='input-group'>
						<Row>
							<Col span={24}>
								<Form.Item name="name">
									<Input autocomplete="off" name='name' placeholder='Username'
										onChange={this.handleChange} className='input-field'
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
							<Form.Item name="regEmail"
								rules={[
									{ type: "email", message: "The input is not valid E-mail!" },
									{ required: true, message: "Please input your E-mail!" }
								]}
							>
								<Input autocomplete="off" name='regEmail' placeholder='* Email'
									onChange={this.handleChange} className='input-field'
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item name="regPassword"
									rules={[{ required: true, message: "Please input Password!" }]}
								>
									<Input autocomplete="off" className='input-field' type='password'
										name='regPassword' placeholder='* Password' onChange={this.handleChange}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item name="confirmPassword"
									rules={[{ required: true, message: "Please input Password!" }]}
								>
									<Input autocomplete="off" className='input-field' type='password'
										name='confirmPassword' placeholder='* Confirm Password' onChange={this.handleChange}
									/>
								</Form.Item>
							</Col>
						</Row>
						
					</Form>
					<button type='submit' className='submit-btn mt-1' onClick={this.register}>Register</button>				
					
				</div>
				</div>

				<Modal
					title={<p style={{color:"#0D4A61", fontWeight:"bold", fontSize:"18px"}}>Reset Password</p>}
					centered
					visible={this.state.pwd_visible}
					onOk={this.resetPassword}
					onCancel={this.clearModal}
					width={500}
				>
					
				<input className='form-control mb-2' name='newpwd' type='email' placeholder='Email' onChange={(e) => this.setState({newpwd: e.target.value})}/>

				</Modal>

				<Modal
					title={<p style={{color:"#0D4A61", fontWeight:"bold", fontSize:"18px"}}>Login Through Phone Number</p>}
					centered
					visible={this.state.visible}
					// onOk={() => this.setState({visible:false})}
					onCancel={() => {
						this.setState({visible:false})
						this.props.dispatch(setHide(false))
					}}
					width={500}
					footer={null}
					afterClose={() =>{ 
						this.setState({isLoading:true})
						browserHistory.push('/login')
					}}
				>
					<OtpLogin/>
				</Modal>
				</LoadingOverlay>
			</div>
		);
	}
}


function  mapStateToProps  (state, ownProps)  {
	return {
		user: state.userSlice.user,
		error: state.userSlice.error,
		loading: state.userSlice.loading
	}
}


export default connect(mapStateToProps)(Login);
