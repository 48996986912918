import { createSlice, createAction} from '@reduxjs/toolkit';


export const dashboardSlice = createSlice({
    name:'dashboard',
    initialState: {
        loading: false,
        hasErrors: null,
        metrics: {},
    },
    reducers: {

        getMetrics: state => {
            state.loading = true
        },

        getMetricsSuccess: (state, { payload }) => {
            state.metrics = payload
            state.loading = false
            state.hasErrors = null
        },

        getMetricsFailure: (state, payload) => {
            state.loading = false
            state.hasErrors = payload
        },

    },
 
})

export const { getMetrics, getMetricsSuccess, getMetricsFailure } = dashboardSlice.actions



export default dashboardSlice.reducer;


